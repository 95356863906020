<template>
  <div v-loading="prefilling" class="eden-page-form">
    <el-form :model="form" label-position="top" ref="form">
      <eden-form-section
        title="Service type information"
        subtitle="Basic information about this particular service type"
      >
        <template slot="form-fields">
          <el-row type="flex">
            <el-col :md="24">
              <el-form-item label="Service">
                <el-input type="text" v-model="form.service" disabled />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :md="24">
              <el-form-item
                label="Service type name"
                prop="service_type_name"
                :rules="validateField()"
              >
                <el-input type="text" v-model="form.service_type_name" />
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section title="" subtitle="">
        <template slot="form-fields">
          <div class="eden-page-form__actions">
            <el-button plain @click="cancel">Cancel</el-button>
            <el-button
              v-if="action === 'add'"
              type="primary"
              :loading="adding"
              @click="add"
              >Add laundry item
            </el-button>
            <el-button v-else type="primary" :loading="updating" @click="update"
              >Save changes
            </el-button>
          </div>
        </template>
      </eden-form-section>
    </el-form>
  </div>
</template>

<script>
import service from "@/requests/services/service";

export default {
  name: "LaundryTypeForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    serviceTypeId: {
      type: [String, Number],
      default: "",
    },
    serviceType: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      prefilling: false,
      adding: false,
      updating: false,
      service_id: "",
      form: {
        service: "Laundry",
        service_type_name: "",
        service_type_category: "single",
        price_and_quantity: [
          {
            overall_quantity: 1,
            overall_quantity_measurement: "bags",
            customer_price: null,
          },
        ],
        overall_quantity: 1,
        customer_price: null,
      },
    };
  },
  created() {
    if (this.action === "edit") {
      this.prefilling = true;
    }

    if (this.action === "edit") {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.serviceType[key];
      });
      setTimeout(() => {
        this.prefilling = false;
      }, 1000);
    }
  },
  methods: {
    cancel() {
      this.$router.push({ name: "services.service", service: "laundry" });
    },
    payload() {
      let payload = JSON.parse(JSON.stringify(this.form));
      payload.price_and_quantity = JSON.stringify(payload.price_and_quantity);
      delete payload.overall_quantity;
      delete payload.customer_price;
      return payload;
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        const payload = this.payload();
        service
          .serviceTypeAdd(payload)
          .then((response) => {
            const { status, message } = response.data;
            if (status) {
              this.success(message);
            } else {
              this.adding = false;
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;
        const payload = this.payload();
        service
          .serviceTypeUpdate(this.serviceTypeId, payload)
          .then((response) => {
            const { status, message } = response.data;
            if (status) {
              this.success(message);
            } else {
              this.updating = false;
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    success(message) {
      this.adding = false;
      this.updating = false;
      this.$message.success(message);
      this.$router.push({
        name: "services.service",
        params: { service: "laundry" },
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
